import CredentialsProvider from './services/credentialsProvider';
import ListenPage from './fragments/ListenPage';
import LoginPage from './fragments/LoginPage';
import Modal from './components/app/Modal';
import React from 'react';
import { Credentials } from './data/structs';
import { CredentialsExpiredError } from './data/errors';
import { Router, navigate } from '@reach/router';
import { BUILD_VERSION } from './data/constants';
import './App.css';

interface Props { }

interface State {
  credentials?: Credentials;
  loginError?: any;
  showExpiredModal: boolean;
}

class App extends React.Component<Props, State> {

  private credentialsProvider: CredentialsProvider;
  private fetchCredentialsPromise?: Promise<Credentials>;

  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      showExpiredModal: false,
    };
    this.credentialsProvider = new CredentialsProvider();
  }

  componentDidMount() {
    document.title = `Jukeboxx v${BUILD_VERSION}`;
    this.tryAnonymousLogin_().catch(_ => navigate(`/login`));
  }

  onLoginSubmit = (email?: string, password?: string) => {
    this.setState({
      loginError: undefined,
    });
    this.tryLogin_(email, password).catch(error => this.setState({
      loginError: error,
    }));
  }

  onExpiredLogInClick = () => {
    // Open in a new window so user's place isn't lost.
    const popupWindow = window.open('/login', '_blank',
        'menubar=0,location=0,toolbar=0,width=480,height=640');
    if (popupWindow) {
      popupWindow.addEventListener('unload', () => {
        this.tryAnonymousLogin_()
        .catch(_ => this.fetchCredentialsPromise = undefined);
      });
    }
  }

  onLogOutClick = () => {
    this.setState({
      credentials: undefined,
      loginError: undefined,
    });
    this.credentialsProvider.clearCredentialsFromDisk();
    navigate('/login');
  }

  onError = (error: Error) => {
    if (error instanceof CredentialsExpiredError) {
      this.setState({
        showExpiredModal: true,
      });
    } else {
      console.error(error);
    }
  }

  private tryAnonymousLogin_(): Promise<Credentials> {
    if (this.fetchCredentialsPromise) {
      return this.fetchCredentialsPromise;
    }
    if (this.state.credentials &&
        this.state.credentials.downloadTokenExpiration > new Date()) {
      return Promise.resolve(this.state.credentials);
    }
    this.fetchCredentialsPromise = this.credentialsProvider.fetchCredentials()
    .then(credentials => {
      this.fetchCredentialsPromise = undefined;
      this.setState({
        credentials: credentials,
        loginError: undefined,
        showExpiredModal: false,
      });
      // Don't navigate if you're already in the app.
      if (!document.location.pathname.startsWith('/listen')) {
        navigate(`/listen/tracks`);
      }
      return credentials;
    });
    this.fetchCredentialsPromise.catch(
        _ => this.fetchCredentialsPromise = undefined);
    return this.fetchCredentialsPromise;
  }

  private tryLogin_(email?: string, password?: string): Promise<Credentials> {
    if (this.fetchCredentialsPromise) {
      return this.fetchCredentialsPromise;
    }
    if (this.state.credentials &&
      this.state.credentials.downloadTokenExpiration > new Date()) {
      return Promise.resolve(this.state.credentials);
    }
    this.fetchCredentialsPromise =
        this.credentialsProvider.fetchCredentials(email, password)
    .then(credentials => {
      this.fetchCredentialsPromise = undefined;
      this.setState({
        credentials: credentials,
        loginError: undefined,
        showExpiredModal: false,
      });
      if (window.opener != null) {
        window.close();
      } else {
        navigate(`/listen/tracks`);
      }
      return credentials;
    });
    this.fetchCredentialsPromise.catch(
        _ => this.fetchCredentialsPromise = undefined);
    return this.fetchCredentialsPromise;
  }

  render() {
    const buttons = <button className="ModalButton"
        onClick={this.onExpiredLogInClick}>Log In</button>;
    return (
      <div className="App">
        <Router>
          <ListenPage
            path="/listen/*"
            credentials={this.state.credentials}
            onLogOutClick={this.onLogOutClick}
            onError={this.onError} />
          <LoginPage path="/login" onSubmit={this.onLoginSubmit} 
              error={this.state.loginError}/>
        </Router>
        <Modal
          open={this.state.showExpiredModal}
          content="Your session has expired."
          buttons={buttons} />
      </div>
    );
  }
}

export default App;
