import React from 'react';
import './EmptyPageContent.css';

interface Props {
  emoji: string;
  message: string;
}

class EmptyPageContent extends React.Component<Props> {
  render() {
    return (
      <div className="EmptyPageContentContainer">
        <div className="EmptyPageContentMessage">
          <p className="EmptyPageContentText Emoji">
            <span role="img" aria-label="No Content">{this.props.emoji}</span>
          </p>
          <p className="EmptyPageContentText">{this.props.message}</p>
        </div>
      </div>
    );
  }
}

export default EmptyPageContent;
