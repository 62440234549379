import { Album, Artist, Credentials, Library } from '../data/structs';
import { CredentialsExpiredError, CacheMissError, SerializationError } from '../data/errors';
import { compareAlbums, compareArtists } from './entitySorts';
import { DOWNLOAD_BASE_PATH } from '../data/constants';

class LibraryProvider {
  static libraryPath = `${DOWNLOAD_BASE_PATH}/tracks/index.json`;

  fetchLibrary(credentials?: Credentials): Promise<Library> {
    if (!credentials) {
      return this.readLibraryFromDisk_();
    }
    return new Promise((resolve, reject) => {
      this.readLibraryFromDisk_()
        .then(library => resolve(library))
        .catch(error => {
          this.requestLibraryFromServer_(credentials)
            .then(library => {
              this.writeLibraryToDisk_(library);
              resolve(library);
            })
            .catch(error => reject(error));
        });
    });
  }

  private readLibraryFromDisk_(): Promise<Library> {
    const libraryJson = window.localStorage.getItem('library');
    if (!libraryJson) {
      return Promise.reject(new CacheMissError());
    }
    const serializedLibrary = JSON.parse(libraryJson);
    const library = this.deserializeLibrary_(serializedLibrary);
    return library ?
        Promise.resolve(library) :
        Promise.reject(new CredentialsExpiredError());
  }

  private writeLibraryToDisk_(library: Library) {
    const serializedLibrary = JSON.stringify(library);
    window.localStorage.setItem('library', serializedLibrary);
  }

  private requestLibraryFromServer_(
    credentials: Credentials
  ): Promise<Library> {
    if (credentials.downloadTokenExpiration < new Date()) {
      return Promise.reject(new CredentialsExpiredError());
    }
    return new Promise((resolve, reject) => {
      const url = `${credentials.downloadUrl}${LibraryProvider.libraryPath}` +
          `?Authorization=${credentials.downloadToken}`;
      fetch(url)
          .then(response => response.json())
          .then(json => {
            const library = this.deserializeLibrary_(json);
            if (!library) {
              reject(new SerializationError());
            } else {
              resolve(library);
            }
          })
          .catch(error => reject(error));
    });
  }

  private deserializeLibrary_(json?: any): Library | null {
    if (!json) {
      return null;
    }
    return json as Library;
  }

  static albumListFromLibrary(library: Library): Array<Album> {
    const albums = Object.values(library.albums) as Array<Album>;
    return albums.sort(compareAlbums);
  }

  static artistListFromLibrary(library: Library): Array<Artist> {
    const artists = Object.values(library.artists) as Array<Artist>;
    return artists.sort(compareArtists);
  }
}

export default LibraryProvider;
