import React from 'react';
import TrackList from '../components/tracks/TrackList';
import { Library, Track } from '../data/structs';
import { compareTracksByTitle } from '../services/entitySorts';
import './AllTracksList.css';

interface Props {
  library: Library;
  selectedTrack?: Track;
  playing: boolean;
  [propName: string]: any;

  onTrackClick(
    track: Track,
    nextQueue: Array<Track>,
    backQueue: Array<Track>,
    shuffle: boolean
  ): void;
  onPlayNext(tracks: Array<Track>): void;
  onPlayLater(tracks: Array<Track>): void;
}

interface State {
  tracks: Array<Track>;
}

class AllTracksList extends React.Component<Props, State> {

  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      tracks: this.getTracks(),
    };
    this.onTrackClick = this.onTrackClick.bind(this);
  }

  getTracks(): Array<Track> {
    if (!this.props.library) {
      return [];
    }
    return Object.values(this.props.library.tracks).sort(compareTracksByTitle);
  }

  onTrackClick(track: Track, nextQueue: Array<Track>, backStack: Array<Track>) {
    this.props.onTrackClick(track, nextQueue, backStack, false);
  }

  onPlayNext = (tracks: Array<Track>) => {
    this.props.onPlayNext(tracks);
  }

  onPlayLater = (tracks: Array<Track>) => {
    this.props.onPlayLater(tracks);
  }

  render() {
    return (
      <div className="TrackListSpacing">
        <div className="TrackListHeader">
          <h2>Tracks</h2>
        </div>
        <TrackList
            library={this.props.library}
            tracks={this.state.tracks}
            selectedTrack={this.props.selectedTrack}
            playing={this.props.playing}
            displayAlbumName={true}
            onTrackClick={this.onTrackClick}
            onPlayNext={this.onPlayNext}
            onPlayLater={this.onPlayLater} />
      </div>
    );
  }
}

export default AllTracksList;
