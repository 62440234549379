import { SERVER_URL } from '../data/constants';
import { Credentials } from '../data/structs';
import { CacheMissError, CredentialsExpiredError, NetworkError, SerializationError } from '../data/errors';

class CredentialsProvider {

  fetchCredentials(email?: string, password?: string): Promise<Credentials> {
    if (email == null || password == null) {
      return this.readCredentialsFromDisk_();
    }
    return new Promise((resolve, reject) => {
      this.readCredentialsFromDisk_()
      .then(credentials => resolve(credentials))
      .catch(_ => {
        this.requestCredentialsFromServer_(email, password)
        .then(credentials => {
          this.writeCredentialsToDisk_(credentials);
          resolve(credentials);
        })
        .catch(error => reject(error));
      })
    });
  }

  clearCredentialsFromDisk() {
    window.localStorage.clear();
  }

  private readCredentialsFromDisk_(): Promise<Credentials> {
    return new Promise((resolve, reject) => {
      const storage = window.localStorage;
      const data = {
        'email': storage.getItem('email'),
        'b2DownloadUrl': storage.getItem('b2DownloadUrl'),
        'b2DownloadToken': storage.getItem('b2DownloadToken'),
        'b2DownloadTokenExpiration': storage.getItem('b2DownloadTokenExpiration'),
      };
      const credentials = this.deserializeCredentials_(data);
      if (!credentials) {
        reject(new CacheMissError());
        return;
      }
      if (credentials.downloadTokenExpiration < new Date()) {
        reject(new CredentialsExpiredError());
        return;
      }
      resolve(credentials);
    });
  }

  private writeCredentialsToDisk_(credentials: Credentials) {
    const storage = window.localStorage;
    storage.setItem('email', credentials.email);
    storage.setItem('b2DownloadUrl', credentials.downloadUrl);
    storage.setItem('b2DownloadToken', credentials.downloadToken);
    const downloadTokenExpirationString =
        `${Math.floor(credentials.downloadTokenExpiration.getTime() / 1000)}`;
    storage.setItem('b2DownloadTokenExpiration', downloadTokenExpirationString);
  }

  private requestCredentialsFromServer_(
    email: string,
    password: string
  ): Promise<Credentials> {
    return new Promise((resolve, reject) => {
      const authUrl = `${SERVER_URL}/auth`;
      const authInitOptions = {
        method: 'POST',
        body: `email=${email}&password=${password}`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };
      fetch(authUrl, authInitOptions)
          .then(response => {
            if (response.ok) {
              return response.json();
            } else {
              reject(new NetworkError(`${response.status} ${response.statusText}`));
            }
          })
          .then(indexJson => {
            indexJson['email'] = email;
            const credentials = this.deserializeCredentials_(indexJson);
            if (!credentials) {
              reject(new SerializationError());
            } else {
              resolve(credentials);
            }
          })
          .catch(error => reject(error));
    });
  }

  private deserializeCredentials_(json: any): Credentials | null {
    const email = json['email'];
    const downloadUrl = json['b2DownloadUrl'];
    const downloadToken = json['b2DownloadToken'];
    let downloadTokenExpirationSecs = json['b2DownloadTokenExpiration'];
    if (typeof downloadTokenExpirationSecs === 'string') {
      downloadTokenExpirationSecs = parseInt(downloadTokenExpirationSecs, 10);
    }
    if (typeof downloadUrl !== 'string' || typeof downloadToken !== 'string' ||
        typeof downloadTokenExpirationSecs !== 'number' ||
        Number.isNaN(downloadTokenExpirationSecs)) {
      return null;
    }
    return {
      email,
      downloadUrl,
      downloadToken,
      downloadTokenExpiration: new Date(downloadTokenExpirationSecs * 1000),
    };
  }
}

export default CredentialsProvider;
