import AlbumList from '../components/albums/AlbumList';
import ImageProvider from '../services/imageProvider';
import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { Album, Artist, Credentials, Library, Track } from '../data/structs';
import { ReactComponent as PlayIcon } from '../icons/play.svg';
import { ReactComponent as ShuffleIcon } from '../icons/shuffle.svg';
import { compareArtistAlbums, compareArtistAlbumsTracks } from '../services/entitySorts';
import { DOWNLOAD_BASE_PATH } from '../data/constants';
import './ArtistAlbumList.css';

interface Props {
  library: Library;
  credentials: Credentials;
  playing: boolean;
  [propName: string]: any;

  onTrackClick(
    track: Track,
    nextQueue: Array<Track>,
    backQueue: Array<Track>,
    shuffle: boolean
  ): void;
  onError(error: Error): void;
}

interface State {
  albums: Array<Album>;
  imageSrc?: string;
}

class ArtistAlbumList extends React.Component<Props, State>  {

  private imageProvider_ = ImageProvider.getInstance();

  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      albums: this.getAlbums(),
    };
  }

  componentDidMount() {
    const artist = this.getArtist();
    const url = `${this.props.credentials.downloadUrl}${DOWNLOAD_BASE_PATH}` +
        `/artist_art/${artist.artName}`;
    this.imageProvider_.fetchImage(url, this.props.credentials)
    .then(imageSrc => {
      this.setState({
        imageSrc,
      });
    }).catch(reason => {
      if (reason instanceof Error) {
        this.props.onError(reason);
      }
    });
  }
  
  getArtist(): Artist {
    return this.props.library.artists[this.props['artistId']]
  }

  getTrackCount(): number {
    return this.getArtist().albumIds
        .map(albumId => this.props.library.albums[albumId].trackIds)
        .reduce((accum, trackIds) => accum + trackIds.length, 0);
  }

  getThirdLine(): string {
    const artist = this.getArtist();
    const albums = artist.albumIds.length > 1 ? 'Albums' : 'Album';
    const trackCount = this.getTrackCount();
    const tracks = trackCount > 1 ? 'Tracks' : 'Track';
    return `${artist.albumIds.length} ${albums} • ${trackCount} ${tracks}`;
  }

  getAlbums(): Array<Album> {
    const library = this.props.library;
    return library.artists[this.props['artistId']].albumIds
        .map(albumId => library.albums[albumId])
        .sort(compareArtistAlbums);
  }

  getTracks(): Array<Track> {
    return this.getArtist().albumIds
        .map(albumId => this.props.library.albums[albumId].trackIds)
        .reduce((accum, trackIds) => accum.concat(trackIds), [])
        .map(trackId => this.props.library.tracks[trackId])
        .map(track => [this.props.library.albums[track.albumId], track] as [Album, Track])
        .sort(compareArtistAlbumsTracks)
        .map(([_, track]) => track);
  }

  onPlayClick = () => {
    const tracks = this.getTracks();
    this.props.onTrackClick(
      tracks[0],
      tracks.slice(1),
      [],
      false);
  }

  onShuffleClick = () => {
    const tracks = this.getTracks();
    this.props.onTrackClick(
      tracks[0],
      tracks.slice(1),
      [],
      true);
  }

  onError = (error: Error) => {
    this.props.onError(error);
  }

  render() {
    const artist = this.getArtist();
    return (
      <div className="ArtistAlbumListSpacing">
        <div className="ArtistAlbumListHeader">
          <div className="ArtistAlbumListArt">
            <CSSTransition classNames="ArtistAlbumListArtImageFade"
                in={this.state.imageSrc != null} timeout={450}>
              <img className="ArtistAlbumListArtImage" src={this.state.imageSrc}
              alt=""></img>
            </CSSTransition>
          </div>
          <div className="ArtistAlbumListInfo">
            <h2 className="ArtistAlbumListTitle">
              {artist.name}
            </h2>
            <div className="AlbumTrackListArtist">
              <span>{artist.area}</span>
            </div>
            <div className="ArtistAlbumListThirdLine">
              <span>{this.getThirdLine()}</span>
            </div>
            <div className="ArtistAlbumListControls">
              <div className="ArtistAlbumListButtons">
                <button className="ArtistAlbumListButton"
                    onClick={this.onPlayClick}>
                  <PlayIcon />
                </button>
                <button className="ArtistAlbumListButton SmallButtonIcon"
                    onClick={this.onShuffleClick}>
                  <ShuffleIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
        <AlbumList
            credentials={this.props.credentials}
            library={this.props.library}
            albums={this.state.albums}
            displayArtistName={false}
            onError={this.onError} />
      </div>
    );
  }
}

export default ArtistAlbumList;
