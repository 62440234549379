import ContextMenu from '../app/ContextMenu';
import React from 'react';
import TrackCell from './TrackCell';
import { Track, Library } from '../../data/structs';
import { navigate } from '@reach/router';
import './TrackList.css';

export interface Props {
  library: Library;
  tracks: Array<Track>;
  selectedTrack?: Track;
  playing: boolean;
  displayAlbumName: boolean;
  playable?: boolean;
  [propName: string]: any;

  onTrackClick?(
    track: Track,
    nextQueue: Array<Track>,
    backQueue: Array<Track>
  ): void;
  onPlayNext(tracks: Array<Track>): void;
  onPlayLater(tracks: Array<Track>): void;
}

interface State {
  contextMenuPosition?: {x: number, y: number};
  contextMenuTrack?: Track;
}

export default class TrackList extends React.Component<Props, State> {

  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {};

    this.onTrackClick = this.onTrackClick.bind(this);
  }

  componentDidMount() {
    window.addEventListener('click', this.onWindowClick);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
  }

  onTrackClick(id: string) {
    if (!this.props.onTrackClick) {
      return;
    }
    const trackIndex = this.props.tracks.findIndex(track => track.id === id);
    if (trackIndex < 0) {
      return [];
    }
    const track = this.props.tracks[trackIndex];
    const nextQueue = this.props.tracks.slice(trackIndex + 1);
    const backQueue = this.props.tracks.slice(0, trackIndex).reverse();
    this.props.onTrackClick(track, nextQueue, backQueue);
  }

  onMenuClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>, 
    trackId: string
  ) => {
    this.setState({
      contextMenuPosition: {
        x: event.clientX,
        y: event.clientY,
      },
      contextMenuTrack: this.props.library.tracks[trackId],
    });
  }

  onMenuOptionClick = (index: number) => {
    if (this.state.contextMenuTrack) {
      if (index === 0) {
        this.props.onPlayNext([this.state.contextMenuTrack]);
      } else if (index === 1) {
        this.props.onPlayLater([this.state.contextMenuTrack]);
      } else if (index === 2) {
        navigate(`/listen/albums/${this.state.contextMenuTrack.albumId}`);
      }
    }
    this.setState({
      contextMenuPosition: undefined,
      contextMenuTrack: undefined,
    });
  }

  onWindowClick = (event: Event) => {
    if (event.target == null || !(event.target instanceof HTMLElement)) {
      return;
    }
    if (!event.target.className.includes('TrackCellMenuButton') &&
        !event.target.className.includes('ContextMenu')) {
      this.setState({
        contextMenuPosition: undefined,
        contextMenuTrack: undefined,
      });
    }
  }

  render() {
    const playable = this.props.playable != null ? this.props.playable : true;
    const trackCells = this.props.tracks.map(track => {
      const selected = this.props.selectedTrack ?
          this.props.selectedTrack.id === track.id : false;
      const album = this.props.library.albums[track.albumId];
      return <TrackCell
        track={track}
        album={album}
        key={track.id}
        selected={selected}
        playing={this.props.playing}
        displayAlbumName={this.props.displayAlbumName}
        playable={playable}
        onClick={() => this.onTrackClick(track.id)}
        onMenuClick={(e) => this.onMenuClick(e, track.id)} />
    });
    return (
      <div className="TrackListContainer">
        <ol className="TrackList">
          {trackCells}
        </ol>
        <ContextMenu display={this.state.contextMenuPosition != null}
            clientClick={this.state.contextMenuPosition || {x: 0, y: 0}}
            offset={{x: -149, y: 0}}
            menuOptions={['Play Next', 'Play After', 'Show in Album']}
            onClick={this.onMenuOptionClick} />
      </div>
    );
  }
}
