import localForage from 'localforage';
import { Credentials } from '../data/structs';
import { CredentialsExpiredError } from '../data/errors';

class ImageProvider {

  private static sharedInstance?: ImageProvider;
  private store: LocalForage;

  private constructor() {
    this.store = localForage.createInstance({
      name: 'Jukeboxx',
      version: 1.0,
      storeName: 'ImageProvider',
    });
  }

  fetchImage(url: string, credentials: Credentials): Promise<string> {
    return new Promise((resolve, reject) => {
      this.store.getItem(url)
      .then(blob => resolve(URL.createObjectURL(blob)))
      .catch(_ => {
        this.requestImage_(url, credentials)
        .then(blob => {
          this.store.setItem(url, blob).catch(_ => ({}));
          resolve(URL.createObjectURL(blob));
        })
        .catch(reason => reject(reason))
      });
    });
  }

  private requestImage_(url: string, credentials: Credentials): Promise<Blob> {
    if (credentials.downloadTokenExpiration < new Date()) {
      return Promise.reject(new CredentialsExpiredError());
    }
    url = `${url}?Authorization=${credentials.downloadToken}`;
    return new Promise((resolve, reject) =>
      fetch(url)
      .then(response => response.blob())
      .then(blob => resolve(blob))
      .catch(error => reject(error))
    );
  }

  static getInstance(): ImageProvider {
    if (!ImageProvider.sharedInstance) {
      ImageProvider.sharedInstance = new ImageProvider();
    }
    return ImageProvider.sharedInstance;
  }
}

export default ImageProvider;
