import AlbumList from '../components/albums/AlbumList';
import ArtistList from '../components/artists/ArtistList';
import EmptyPageContent from '../components/app/EmptyPageContent';
import TrackList from '../components/tracks/TrackList';
import React, { SyntheticEvent } from 'react';
import SearchEngine, { SearchResult } from '../services/searchEngine';
import { Credentials, Library, Track } from '../data/structs';
import './SearchPage.css';

interface Props {
  credentials: Credentials;
  library: Library;
  searchEngine: SearchEngine;
  [propName: string]: any;
  
  onTrackClick(
    track: Track,
    nextQueue: Array<Track>,
    backQueue: Array<Track>,
    shuffle: boolean
  ): void;
  onPlayNext(tracks: Array<Track>): void;
  onPlayLater(tracks: Array<Track>): void;
  onError(error: Error): void;
}

interface State {
  query: string;
  result: SearchResult;
}

class SearchPage extends React.Component<Props, State> {

  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      query: '',
      result: this.props.searchEngine.search(''),
    };
  }

  onInputChange = (event: SyntheticEvent) => {
    const target = event.target as HTMLInputElement;
    this.setState({
      query: target.value,
    });
    this.setState({
      result: this.props.searchEngine.search(target.value),
    });
  }

  onError = (error: Error) => {
    this.props.onError(error);
  }

  onTrackClick = (
    track: Track,
    nextQueue: Array<Track>,
    backQueue: Array<Track>
  ) => {
    this.props.onTrackClick(track, nextQueue, backQueue, false);
  }

  onPlayNext = (tracks: Array<Track>) => {
    this.props.onPlayNext(tracks);
  }

  onPlayLater = (tracks: Array<Track>) => {
    this.props.onPlayLater(tracks);
  }

  render() {
    const resultsEmpty =
      this.state.result.albums.length === 0 &&
      this.state.result.artists.length === 0 &&
      this.state.result.tracks.length === 0;
    const result = resultsEmpty ? (
      <EmptyPageContent
        emoji="🙈"
        message="No matches found!" />
    ) : (
      <div>
        <AlbumList
            credentials={this.props.credentials}
            library={this.props.library}
            albums={this.state.result.albums}
            displayArtistName={true}
            onError={this.onError} />
        <ArtistList
            credentials={this.props.credentials}
            library={this.props.library}
            artists={this.state.result.artists}
            onError={this.onError}/>
        <TrackList
            library={this.props.library}
            tracks={this.state.result.tracks}
            selectedTrack={this.props.selectedTrack}
            playing={this.props.playing}
            displayAlbumName={true}
            onTrackClick={this.onTrackClick}
            onPlayNext={this.onPlayNext}
            onPlayLater={this.onPlayLater} />
      </div>
    );
    const spacingClass = resultsEmpty ?
        "SearchPageSpacing Empty" : "SearchPageSpacing";
    return (
      <div className={spacingClass}>
        <div className="SearchPageHeader">
          <h2>Search</h2>
        </div>
        <input
            className="SearchInput"
            type="search"
            autoCorrect="off"
            spellCheck={false}
            autoCapitalize="off"
            placeholder="Search for Tracks, Artists, or Albums"
            value={this.state.query}
            onChange={this.onInputChange} />
        {result}
      </div>
    );
  }
}

export default SearchPage;
