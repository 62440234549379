import { Album, Artist, Track } from '../data/structs';

export function compareTracksByTrack(trackA: Track, trackB: Track): number {
  if (trackA.track < trackB.track) { return -1; }
  else if (trackA.track > trackB.track) { return 1; }
  else { return 0; }
}
export function compareTracksByTitle(trackA: Track, trackB: Track): number {
  if (trackA.titleSort < trackB.titleSort) { return -1; }
    else if (trackA.titleSort > trackB.titleSort) { return 1; }
    else { return 0; }
}

export function compareArtistAlbumsTracks(
  pairA: [Album, Track],
  pairB: [Album, Track]
): number {
  const [albumA, trackA] = pairA;
  const [albumB, trackB] = pairB;
  if (albumA.year < albumB.year) { return -1; }
  else if (albumA.year > albumB.year) { return 1; }
  else {
    if (albumA.titleSort < albumB.titleSort) { return -1; }
    else if (albumA.titleSort > albumB.titleSort) { return 1; }
    else {
      if (trackA.track < trackB.track) { return -1; }
      else if (trackA.track > trackB.track) { return 1; }
      else { return 0; }
    }
  }
}

export function compareArtistAlbums(albumA: Album, albumB: Album): number {
  if (albumA.year < albumB.year) { return -1; }
  else if (albumA.year > albumB.year) { return 1; }
  else {
    if (albumA.titleSort < albumB.titleSort) { return -1; }
    else if (albumA.titleSort > albumB.titleSort) { return 1; }
    else { return 0; }
  }
}

export function compareAlbums(albumA: Album, albumB: Album): number {
  if (albumA.artistSort < albumB.artistSort) { return -1; }
  else if (albumA.artistSort > albumB.artistSort) { return 1; }
  else { 
    if (albumA.year < albumB.year) { return -1; }
    else if (albumA.year > albumB.year) { return 1; }
    else { 
      if (albumA.titleSort < albumB.titleSort) { return -1; }
      else if (albumA.titleSort > albumB.titleSort) { return 1; }
      else { return 0; }
    }
  }
}

export function compareArtists(artistA: Artist, artistB: Artist): number {
  if (artistA.nameSort < artistB.nameSort) { return -1; }
  else if (artistA.nameSort > artistB.nameSort) { return 1; }
  else { return 0; }
}
