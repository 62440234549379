import AlbumList from '../components/albums/AlbumList';
import LibraryProvider from '../services/libraryProvider';
import React from 'react';
import { Album, Credentials, Library } from '../data/structs';
import './AllAlbumsList.css';

interface Props {
  credentials: Credentials;
  library: Library;
  [propName: string]: any;

  onError(error: Error): void;
}

interface State {
  albums: Array<Album>;
}

class AllAlbumsList extends React.Component<Props, State> {

  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      albums: LibraryProvider.albumListFromLibrary(this.props.library),
    };
  }

  onError = (error: Error) => {
    this.props.onError(error);
  }

  render() {
    return (
      <div className="AlbumListSpacing">
        <div className="AlbumListHeader">
          <h2>Albums</h2>
        </div>
        <AlbumList
            credentials={this.props.credentials}
            library={this.props.library}
            albums={this.state.albums}
            displayArtistName={true}
            onError={this.onError} />
      </div>
    );
  }
}

export default AllAlbumsList;
