import ContextMenu from '../components/app/ContextMenu';
import ImageProvider from '../services/imageProvider';
import React from 'react';
import TrackList from '../components/tracks/TrackList';
import { CSSTransition } from 'react-transition-group';
import { Album, Credentials, Library, Track } from '../data/structs';
import { Link } from '@reach/router';
import { ReactComponent as PlayIcon } from '../icons/play.svg';
import { ReactComponent as ShuffleIcon } from '../icons/shuffle.svg';
import { compareTracksByTrack } from '../services/entitySorts';
import { DOWNLOAD_BASE_PATH } from '../data/constants';
import './AlbumTrackList.css';

interface Props {
  library: Library;
  credentials: Credentials;
  selectedTrack?: Track;
  playing: boolean;
  [propName: string]: any;

  onTrackClick(
    track: Track,
    nextQueue: Array<Track>,
    backQueue: Array<Track>,
    shuffle: boolean
  ): void;
  onPlayNext(tracks: Array<Track>): void;
  onPlayLater(tracks: Array<Track>): void;
  onError(error: Error): void;
}

interface State {
  tracks: Array<Track>;
  imageSrc?: string;
  contextMenuPosition?: {x: number, y: number};
}

class AlbumTrackList extends React.Component<Props, State>  {

  private imageProvider_ = ImageProvider.getInstance();

  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      tracks: this.getTracks(),
    };
    this.onTrackClick = this.onTrackClick.bind(this);
    this.onPlayClick = this.onPlayClick.bind(this);
    this.onShuffleClick = this.onShuffleClick.bind(this);
  }

  componentDidMount() {
    window.addEventListener('click', this.onWindowClick);

    const album = this.getAlbum();
    const url = `${this.props.credentials.downloadUrl}${DOWNLOAD_BASE_PATH}` +
        `/album_art/${album.artName}`;
    this.imageProvider_.fetchImage(url, this.props.credentials)
    .then(imageSrc => {
      this.setState({
        imageSrc,
      });
    }).catch(reason => {
      if (reason instanceof Error) {
        this.props.onError(reason);
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onWindowClick);
  }
  
  getAlbum(): Album {
    return this.props.library.albums[this.props['albumId']]
  }

  getThirdLine(): string {
    const album = this.getAlbum();
    const tracks = album.trackIds.length > 1 ? 'Tracks' : 'Track';
    return `${album.year} • ${album.trackIds.length} ${tracks}`;
  }

  getTracks(): Array<Track> {
    const library = this.props.library;
    return library.albums[this.props['albumId']].trackIds
        .map(trackId => library.tracks[trackId])
        .sort(compareTracksByTrack);
  }

  onTrackClick(track: Track, nextQueue: Array<Track>, backStack: Array<Track>) {
    this.props.onTrackClick(track, nextQueue, backStack, false);
  }

  onPlayNext = (tracks: Array<Track>) => {
    this.props.onPlayNext(tracks);
  }

  onPlayLater = (tracks: Array<Track>) => {
    this.props.onPlayLater(tracks);
  }

  onPlayClick() {
    this.props.onTrackClick(
      this.state.tracks[0],
      this.state.tracks.slice(1),
      [],
      false);
  }

  onShuffleClick() {
    this.props.onTrackClick(
      this.state.tracks[0],
      this.state.tracks.slice(1),
      [],
      true);
  }

  onMenuClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    this.setState({
      contextMenuPosition: {
        x: event.clientX,
        y: event.clientY,
      },
    });
  }

  onMenuOptionClick = (index: number) => {
    if (index === 0) {
      this.props.onPlayNext(this.state.tracks);
    } else if (index === 1) {
      this.props.onPlayLater(this.state.tracks);
    }
    this.setState({
      contextMenuPosition: undefined,
    });
  }

  onWindowClick = (event: Event) => {
    if (event.target == null || !(event.target instanceof HTMLElement)) {
      return;
    }
    if (!event.target.className.includes('AlbumTrackListMenuButton') &&
        !event.target.className.includes('ContextMenu')) {
      this.setState({
        contextMenuPosition: undefined,
      });
    }
  }

  render() {
    const album = this.getAlbum();
    const artist = this.props.library.artists[album.artistId];
    return (
      <div className="AlbumTrackListSpacing">
        <div className="AlbumTrackListHeader">
          <div className="AlbumTrackListArt">
            <CSSTransition classNames="AlbumTrackListArtImageFade"
                in={this.state.imageSrc != null} timeout={450}>
              <img className="AlbumTrackListArtImage" src={this.state.imageSrc}
              alt=""></img>
            </CSSTransition>
          </div>
          <div className="AlbumTrackListInfo">
            <h2 className="AlbumTrackListTitle">
              {album.title}
            </h2>
            <div className="AlbumTrackListArtist">
              <span>
                <Link className="AlbumTrackListLink"
                    to={`/listen/artists/${artist.id}`}>
                  {artist.name}
                </Link>
              </span>
            </div>
            <div className="AlbumTrackListThirdLine">
              <span>{this.getThirdLine()}</span>
            </div>
            <div className="AlbumTrackListControls">
              <div className="AlbumTrackListButtons">
                <button className="AlbumTrackListButton"
                    onClick={this.onPlayClick}>
                  <PlayIcon />
                </button>
                <button className="AlbumTrackListButton SmallButtonIcon"
                    onClick={this.onShuffleClick}>
                  <ShuffleIcon />
                </button>
                <button className="AlbumTrackListMenuButton" onClick={this.onMenuClick}>
                  <div className="AlbumTrackListMenuButtonText">...</div>
                </button>
              </div>
            </div>
          </div>
          <ContextMenu display={this.state.contextMenuPosition != null}
            clientClick={this.state.contextMenuPosition || {x: 0, y: 0}}
            offset={{x: 0, y: 0}}
            menuOptions={['Play Next', 'Play After']}
            onClick={this.onMenuOptionClick} />
        </div>
        <TrackList
            library={this.props.library}
            tracks={this.state.tracks}
            selectedTrack={this.props.selectedTrack}
            playing={this.props.playing}
            displayAlbumName={false}
            onTrackClick={this.onTrackClick}
            onPlayNext={this.onPlayNext}
            onPlayLater={this.onPlayLater} />
      </div>
    );
  }
}

export default AlbumTrackList;
