import ArtistList from '../components/artists/ArtistList';
import LibraryProvider from '../services/libraryProvider';
import React from 'react';
import { Artist, Credentials, Library } from '../data/structs';
import './AllArtistsList.css';

interface Props {
  credentials: Credentials;
  library: Library;
  [propName: string]: any;

  onError(error: Error): void;
}

interface State {
  artists: Array<Artist>;
}

class AllArtistsList extends React.Component<Props, State> {

  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      artists: LibraryProvider.artistListFromLibrary(this.props.library),
    };
  }

  onError = (error: Error) => {
    this.props.onError(error);
  }

  render() {
    return (
      <div className="ArtistListSpacing">
        <div className="ArtistListHeader">
          <h2>Artists</h2>
        </div>
        <ArtistList
          credentials={this.props.credentials}
          library={this.props.library}
          artists={this.state.artists}
          onError={this.onError} />
      </div>
    );
  }
}

export default AllArtistsList;
