import React from 'react';

interface Props {
  open: boolean;
  content: React.ReactNode;
  buttons: React.ReactNode;
}

class Modal extends React.Component<Props, {}> {
  render() {
    const modalClass = 'Modal ' + (this.props.open ? '' : 'Closed');
    const modalOverlayClass = 'ModalOverlay ' + (this.props.open ? '' : 'Closed');
    return (
      <div>
        <div className={modalClass}>
            <div className="ModalScroll">
              {this.props.content}
            </div>
            <div className="ModalButtons">
              {this.props.buttons}
            </div>
          </div>
          <div className={modalOverlayClass}></div>
      </div>
    );
  }
}


export default Modal;
