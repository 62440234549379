import React from 'react';
import { Dictionary } from '../../data/structs';

interface Props {
  path: string;
  className?: string;
}

class MainView extends React.Component<Props> {
  private scrollElementRef = React.createRef<HTMLDivElement>();
  private scrollPositions: Dictionary<number> = {};

  componentDidMount() {
    if (this.scrollElementRef.current) {
      this.scrollElementRef.current.addEventListener(
          'scroll', this.saveScrollPosition);
    }
  }

  componentWillUnmount() {
    if (this.scrollElementRef.current) {
      this.scrollElementRef.current.removeEventListener(
          'scroll', this.saveScrollPosition);
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (!this.scrollElementRef.current || prevProps.path === this.props.path) {
      return;
    }
    const scrollElement = this.scrollElementRef.current;
    // Check if seen this new page before
    if (this.props.path in this.scrollPositions) {
      scrollElement.scrollTo(0, this.scrollPositions[this.props.path]);
    } else {
      // Otherwise scroll to top.
      scrollElement.scrollTo(0, 0);
    }
  }

  saveScrollPosition = () => {
    // Save the previous scroll position
    if (this.scrollElementRef.current) {
      this.scrollPositions[this.props.path] =
          this.scrollElementRef.current.scrollTop;
    }
  }

  render() {
    return (
      <div className={this.props.className} ref={this.scrollElementRef}>
        {this.props.children}
      </div>
    );
  }
}

export default MainView;
