import EmptyPageContent from '../components/app/EmptyPageContent';
import React from 'react';
import TrackList from '../components/tracks/TrackList';
import { Library, Track } from '../data/structs';
import './QueueList.css';

interface Props {
  library: Library;
  playing: boolean;
  selectedTrack?: Track;
  nextQueue: Array<Track>;
  [propName: string]: any;

  onTrackClick(
    track: Track,
    nextQueue: Array<Track>,
    backQueue: Array<Track>,
    shuffle: boolean
  ): void;
  onPlayNext(tracks: Array<Track>): void;
  onPlayLater(tracks: Array<Track>): void;
}

class QueueList extends React.Component<Props> {

  onTrackClick = (
    track: Track,
    nextQueue: Array<Track>,
    backStack: Array<Track>
  ) => {
    if (track === this.props.selectedTrack) {
      this.props.onTrackClick(track, nextQueue, backStack, false);
    }
  }

  onPlayNext = (tracks: Array<Track>) => {
    this.props.onPlayNext(tracks);
  }

  onPlayLater = (tracks: Array<Track>) => {
    this.props.onPlayLater(tracks);
  }

  render() {
    const tracks = this.props.selectedTrack ?
        [this.props.selectedTrack].concat(this.props.nextQueue) :
        this.props.nextQueue;
    
  const result = tracks.length === 0 ?
    <EmptyPageContent
        emoji="🙉"
        message="Nothing's playing right now!" /> :
    <TrackList
        library={this.props.library}
        tracks={tracks}
        selectedTrack={this.props.selectedTrack}
        playing={this.props.playing}
        displayAlbumName={true}
        playable={false}
        onTrackClick={this.onTrackClick}
        onPlayNext={this.onPlayNext}
        onPlayLater={this.onPlayLater} />
    const spacingClass = tracks.length === 0 ?
        "QueueListSpacing Empty" : "QueueListSpacing";
    return (
      <div className={spacingClass}>
        <div className="QueueListHeader">
          <h2>Up Next</h2>
        </div>
        {result}
      </div>
    );
  }
}

export default QueueList;
