import { Album, Artist, Library, Track, Dictionary, instanceOfAlbum, instanceOfArtist, instanceOfTrack } from "../data/structs";

export interface SearchResult {
  albums: Array<Album>;
  artists: Array<Artist>;
  tracks: Array<Track>;
}

type Entity = Album|Artist|Track;

interface SearchNode {
  atom: string;
  children: Dictionary<SearchNode>;
  entities: Array<Entity>
}

// TODO: Optimization: Do this at gen-distro time.
// TODO: Optimization: Compress the tree by combining child-less nodes.
// TODO: Optimization: Use undefined instead of {} for empty children.
// TODO: Optimization: Use undefined instead of [] for empty entities.
// TODO: Optimization: Stop search earlier given display limit.
// TODO: Optimization: Make type checking easier, or keep entities separate.
// TODO: Cleanup: genericize the crawl.
// TODO: Feature: Tokenize the query (q: Quest -> A Tribe Called Quest)
// TODO: Feature: Add tracks and albums for artist name, tracks for album
// TODO: Feature: Make the default results "recents".

// A surprisingly not-that-slow originally-joke implementation of a library
// search engine that builds the index in JS.
// Data structure is a trie.
class SearchEngine {

  private library: Library;
  private tree?: SearchNode

  constructor(library: Library) {
    this.library = library;
  }

  private static buildTree(library: Library): SearchNode {
    const root: SearchNode = {
      atom: '',
      children: {},
      entities: [],
    };

    Object.values(library.albums)
      .forEach(album => SearchEngine.addEntity(root, album.title, album));
    Object.values(library.artists)
      .forEach(artist => SearchEngine.addEntity(root, artist.name, artist));
    Object.values(library.tracks)
      .forEach(track => SearchEngine.addEntity(root, track.title, track));

    return root;
  }

  private static addEntity(
    root: SearchNode, 
    entityName: string,
    entity: Entity
  ): SearchNode {
    entityName = entityName.toLowerCase().trim();
    let node = root;
    for (let charIndex = 0; charIndex < entityName.length; charIndex++) {
      const char = entityName.charAt(charIndex);
      const isLastChar = charIndex === entityName.length - 1;
      let child: SearchNode | undefined = node.children[char];
      if (child != null) {
        child.entities = isLastChar ?
            child.entities.concat([entity]) : child.entities;
        node = child;
        continue;
      }
      child = {
        atom: char,
        children: {},
        entities: isLastChar ? [entity] : [],
      };
      node.children[char] = child;
      node = child;
    }
    return root;
  }

  private static findMatch(
    root: SearchNode,
    query: string
  ): SearchNode | undefined {
    let node = root;
    let match = undefined;
    for (let charIndex = 0; charIndex < query.length; charIndex++) {
      const char = query.charAt(charIndex);
      const isLastChar = charIndex === query.length - 1;
      let child: SearchNode | undefined = node.children[char];
      if (child == null) {
        // No dice!
        break;
      }
      if (isLastChar) {
        match = child;
        break;
      }
      node = child;
    }
    return match;
  }

  private static coalesceResults(match: SearchNode): SearchResult {
    // BFS.
    let queue = [match];
    let results: Array<Entity> = [];
    while (queue.length > 0) {
      const node = queue.splice(0, 1)[0];
      results = results.concat(node.entities);
      queue = queue.concat(Object.values(node.children));
    }
    const albums = results.reduce((accum: Array<Album>, entity) =>
        instanceOfAlbum(entity) ? accum.concat([entity]) : accum, []);
    const artists = results.reduce((accum: Array<Artist>, entity) =>
        instanceOfArtist(entity) ? accum.concat([entity]) : accum, []);
    const tracks = results.reduce((accum: Array<Track>, entity) =>
        instanceOfTrack(entity) ? accum.concat([entity]) : accum, []);
    return {
      albums,
      artists,
      tracks,
    };
  }

  search(query: string): SearchResult {
    query = query.toLowerCase().trim();
    if (query.length === 0) {
      return {
        albums:  Object.values(this.library.albums).slice(0, 5),
        artists: Object.values(this.library.artists).slice(0, 5),
        tracks: Object.values(this.library.tracks).slice(0, 10),
      };
    }
    if (!this.tree) {
      this.tree = SearchEngine.buildTree(this.library);
    }
    
    const match = SearchEngine.findMatch(this.tree, query);
    if (match == null) {
      return {
        albums: [],
        artists: [],
        tracks: [],
      };
    }

    const result = SearchEngine.coalesceResults(match);
    return result;
  }
}

export default SearchEngine;
