import ImageProvider from '../../services/imageProvider';
import React, { SyntheticEvent } from 'react';
import { Album, Credentials, Track } from '../../data/structs';
import { BUILD_VERSION, DOWNLOAD_BASE_PATH } from '../../data/constants';
import { CSSTransition } from 'react-transition-group';
import { Link } from '@reach/router'; 
import { ReactComponent as BackIcon } from '../../icons/back.svg';
import { ReactComponent as NextIcon } from '../../icons/next.svg';
import { ReactComponent as PauseIcon } from '../../icons/pause.svg';
import { ReactComponent as PlayIcon } from '../../icons/play.svg';
import { ReactComponent as RepeatIcon } from '../../icons/repeat.svg';
import { ReactComponent as ShuffleIcon } from '../../icons/shuffle.svg';
import { ReactComponent as VolumeHighIcon } from '../../icons/volume_high.svg';
import { ReactComponent as VolumeMediumIcon } from '../../icons/volume_medium.svg';
import { ReactComponent as VolumeLowIcon } from '../../icons/volume_low.svg';
import { ReactComponent as VolumeMuteIcon } from '../../icons/volume_mute.svg';
import './NowPlayingBar.css';

interface Props {
  credentials?: Credentials;
  album?: Album;
  track?: Track;
  progress: number;
  duration: number;
  playing: boolean;
  buffering: boolean;
  volume: number;

  shuffle: boolean;
  repeat: boolean;

  onProgressChange?(progress: number): void;
  onPlayPauseClick?(): void;
  onBackClick?(): void;
  onForwardClick?(): void;
  onVolumeChange?(volume: number): void;
  onShuffleToggle?(): void;
  onRepeatToggle?(): void;
  onError(error: Error): void;
}

interface State {
  albumArtImageSrc?: string;
}

class NowPlayingBar extends React.Component<Props, State> {

  private imageProvider_ = ImageProvider.getInstance();
  lastUnmutedVolume: number;

  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {};
    this.lastUnmutedVolume = this.props.volume !== 0 ? this.props.volume : 1;

    this.onPlayPauseClick = this.onPlayPauseClick.bind(this);
    this.onBackClick = this.onBackClick.bind(this);
    this.onForwardClick = this.onForwardClick.bind(this);
    this.onProgressChange = this.onProgressChange.bind(this);
    this.onVolumeChange = this.onVolumeChange.bind(this);
    this.onMuteToggleClick = this.onMuteToggleClick.bind(this);
    this.onShuffleClick = this.onShuffleClick.bind(this);
    this.onRepeatClick = this.onRepeatClick.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.track && this.props.playing) {
      let title = this.props.track.title;
      if (this.props.album) {
        title += ` • ${this.props.album.title}`;
      }
      document.title = title;
    } else {
      document.title = `Jukeboxx v${BUILD_VERSION}`;
    }
    if (this.props.album !== prevProps.album && this.props.credentials) {
      if (!this.props.album) {
        this.setState({
          albumArtImageSrc: '',
        });
        return;
      }
      // Prepare a new image source.
      const url = `${this.props.credentials.downloadUrl}${DOWNLOAD_BASE_PATH}` +
          `/album_art/${this.props.album.artName}`;
      // TODO: race condition here when switching albums quickly.
      this.imageProvider_.fetchImage(url, this.props.credentials)
      .then(imageSrc => {
        this.setState({
          albumArtImageSrc: imageSrc,
        });
      })
      .catch(reason => {
        if (reason instanceof Error) {
          this.props.onError(reason);
        }
      });
    }
  }

  // Input reactions

  onPlayPauseClick() {
    if (!this.props.onPlayPauseClick || !this.props.track) {
      return;
    }
    this.props.onPlayPauseClick();
  }

  onBackClick() {
    if (!this.props.onBackClick || !this.props.track) {
      return;
    }
    this.props.onBackClick();
  }

  onForwardClick() {
    if (!this.props.onForwardClick || !this.props.track) {
      return;
    }
    this.props.onForwardClick();
  }

  onProgressChange(event: SyntheticEvent) {
    if (!this.props.onProgressChange) {
      return;
    }
    const input = event.target as HTMLInputElement;
    this.props.onProgressChange(parseInt(input.value, 10));
  }

  onMuteToggleClick(event: SyntheticEvent) {
    if (!this.props.onVolumeChange) {
      return;
    }
    if (this.props.volume > 0) {
      this.lastUnmutedVolume = this.props.volume;
      this.props.onVolumeChange(0);
    } else {
      this.props.onVolumeChange(this.lastUnmutedVolume);
    }
  }

  onVolumeChange(event: SyntheticEvent) {
    if (!this.props.onVolumeChange) {
      return;
    }
    const input = event.target as HTMLInputElement;
    let newVolume = parseInt(input.value, 10) / 100;
    if (newVolume === 0) {
      this.lastUnmutedVolume = this.props.volume;
    } 
    this.props.onVolumeChange(newVolume);
  }

  onShuffleClick() {
    if (this.props.onShuffleToggle) {
      this.props.onShuffleToggle();
    }
  }

  onRepeatClick() {
    if (this.props.onRepeatToggle) {
      this.props.onRepeatToggle();
    }
  }

  secondsToTimeString(seconds: number): string {
    const date = new Date(0);
    if (seconds === Infinity || seconds === -Infinity) {
      seconds = 0;
    }
    date.setSeconds(seconds);
    return date.toISOString().substr(14, 5);
  }

  volumeIcon(volume: number): JSX.Element {
    if (volume === 0) {
      return <VolumeMuteIcon />;
    } else if (volume < 0.33) {
      return <VolumeLowIcon />;
    } else if (volume < 0.66) {
      return <VolumeMediumIcon />;
    } else {
      return <VolumeHighIcon />;
    }
  }

  imgSrc(album?: Album): string {
    if (!album || !this.props.credentials) {
      return '';
    }
    return `${this.props.credentials.downloadUrl}${DOWNLOAD_BASE_PATH}` +
        `/album_art/${album.artName}?Authorization=` +
        `${this.props.credentials.downloadToken}`;
  }

  render() {
    const trackName = this.props.track ? this.props.track.title : undefined;
    const artistName = this.props.track ? this.props.track.artist : undefined;
    const artistId = this.props.track ? this.props.track.artistId : undefined;
    const albumName = this.props.album ? this.props.album.title : undefined;
    const albumId = this.props.album ? this.props.album.id : undefined;
    let shuffleButtonClassName = "PlayerButton SmallButtonIcon";
    if (this.props.shuffle) {
      shuffleButtonClassName += " Highlighted";
    }
    let repeatButtonClassName = "PlayerButton SmallButtonIcon";
    if (this.props.repeat) {
      repeatButtonClassName += " Highlighted";
    }
    const mainButtonClass = this.props.track ? "PlayerButton" : "PlayerButton Disabled";
    const middleElement = this.props.buffering ? (
      <div className="PlayerSpinnerContainer">
        <div className="PlayerSpinner"></div>
      </div>
    ) : (
      <button className={mainButtonClass} onClick={this.onPlayPauseClick}>
        { this.props.playing ? <PauseIcon /> : <PlayIcon /> }
      </button>
    );
    return (
      <div className="NowPlayingBar">
        <div className="NowPlayingBarLeft">
          <div className="NowPlayingArtwork">
            <CSSTransition classNames="NowPlayingArtworkImageFade"
                in={this.state.albumArtImageSrc != null} timeout={350}>
              <img className="NowPlayingArtworkImage"
                  src={this.state.albumArtImageSrc}
                  alt="">
              </img>
            </CSSTransition>
          </div>
          <div className="NowPlayingTitle">
            <div className="NowPlayingTitleTitle">
              {trackName}
            </div>
            <div className="NowPlayingTitleSubtitle">
              <Link className="NowPlayingTitleSubtitleLink"
                  to={`/listen/artists/${artistId}`}>
                {artistName}
              </Link>
            </div>
            <div className="NowPlayingTitleSubtitle">
              <Link className="NowPlayingTitleSubtitleLink"
                  to={`/listen/albums/${albumId}`}>
                {albumName}
              </Link>
            </div>
          </div>
        </div>
        <div className="NowPlayingBarCenter">
          <div className="ProgressBar">
            <div className="ProgressBarTime">
              {this.secondsToTimeString(this.props.progress)}
            </div>
            <input
              className="ProgressBarInput"
              type="range"
              min="0"
              max={this.props.duration}
              disabled={this.props.buffering || !this.props.track}
              value={this.props.progress}
              onChange={this.onProgressChange} />
            <div className="ProgressBarTime">
              {this.secondsToTimeString(this.props.duration)}
            </div>
          </div>
          <div className="PlayerControls">
            <button className={shuffleButtonClassName}
                onClick={this.onShuffleClick}>
              <ShuffleIcon />
            </button>
            <button className={`${mainButtonClass} SmallButtonIcon`}
                onClick={this.onBackClick}>
              <BackIcon />
            </button>
              {middleElement}
            <button className={`${mainButtonClass} SmallButtonIcon`}
                onClick={this.onForwardClick}>
              <NextIcon />
            </button>
            <button className={repeatButtonClassName}
                onClick={this.onRepeatClick}>
              <RepeatIcon />
            </button>
          </div>
        </div>
        <div className="NowPlayingBarRight">
          <div className="SecondaryControls">
            <div className="VolumeControl">
              <button className="MuteToggleButton"
                  onClick={this.onMuteToggleClick}>
                {this.volumeIcon(this.props.volume)}
              </button>
              <input
                className="VolumeInput"
                type="range"
                min="0"
                max="100"
                value={this.props.volume * 100}
                onChange={this.onVolumeChange}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NowPlayingBar;
