import ArtistCell from './ArtistCell';
import React from 'react';
import { Artist, Credentials, Library } from '../../data/structs';
import './ArtistList.css';

interface Props {
  credentials: Credentials;
  library: Library;
  artists: Array<Artist>;

  onError(error: Error): void;
}

interface State {
  intersectionObserver?: IntersectionObserver;
  intersected: Set<string>;
}

class ArtistList extends React.Component<Props, State> {

  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      intersected: new Set(),
    };
  }

  componentDidMount() {
    if (!('IntersectionObserver' in window)) {
      // Load all images at once :|
      this.setState({
        intersected: new Set(Object.keys(this.props.library.albums)),
      });
    }
    if (this.state.intersectionObserver) {
      return;
    }
    const intersectionObserver = new IntersectionObserver(
      (e) => this.onIntersection_(e), {
        root: document.querySelectorAll('.MainView')[0],
        threshold: 0.05,
      });
    this.setState({
      intersectionObserver,
    });
  }

  componentWillUnmount() {
    if (this.state.intersectionObserver) {
      this.state.intersectionObserver.disconnect();
    }
  }

  private onIntersection_(entries: Array<IntersectionObserverEntry>) {
    const newIntersected = entries.reduce((accum: Array<string>, entry) => {
      if (entry.intersectionRatio <= 0) {
        return accum;
      }
      this.state.intersectionObserver!.unobserve(entry.target);
      const img = entry.target as HTMLImageElement;
      const albumId = img.dataset.id;
      if (albumId != null) {
        accum.push(albumId);
        return accum;
      } else {
        return accum;
      }
    }, []);
    const oldIntersected = Array.from(this.state.intersected);
    const intersected = new Set(newIntersected.concat(oldIntersected));
    this.setState({
      intersected,
    });
  }

  private onAlbumCellError_ = (error: Error) => {
    this.props.onError(error);
  }

  render() {
    const cells = this.props.artists.map((artist, index) =>
      <ArtistCell
          key={artist.id}
          artist={artist}
          index={index}
          credentials={this.props.credentials}
          intersectionObserver={this.state.intersectionObserver}
          intersected={this.state.intersected.has(artist.id)}
          onError={this.onAlbumCellError_}/>
    );
    return (
      <div className="ArtistListContainer">
        <div className="ArtistList">
          {cells}
          <div className="ArtistListTail"></div>
        </div>
      </div>
    );
  }
}

export default ArtistList;
