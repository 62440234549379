export class CacheMissError extends Error {
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class CredentialsExpiredError extends Error {
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class HlsError extends Error {
  constructor(errorData: Hls.errorData) {
    super(errorData.details);
    Object.setPrototypeOf(this, new.target.prototype);
  } 
}

export class NetworkError extends Error {
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class PlaybackError extends Error {
  constructor(reason?: any) {
    super(reason);
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class SerializationError extends Error {
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
