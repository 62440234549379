import React, { SyntheticEvent } from 'react';
import { Link } from '@reach/router';
import { ReactComponent as PauseIcon } from '../../icons/pause.svg';
import { ReactComponent as PlayIcon } from '../../icons/play.svg';
import { ReactComponent as SpeakerIcon } from '../../icons/volume_high.svg';
import { Track, Album } from '../../data/structs';
import './TrackCell.css';

interface Props {
  track?: Track;
  album?: Album;
  selected: boolean;
  playing: boolean;
  displayAlbumName: boolean;
  playable: boolean;

  onClick(event: SyntheticEvent): void;
  onMenuClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
}

interface State {
  hover: boolean;
}

class TrackCell extends React.Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      hover: false,
    };
  }

  onMouseEnter = () => {
    this.setState({
      hover: true,
    });
  }

  onMouseLeave = () => {
    this.setState({
      hover: false,
    });
  }

  onClick = (event: SyntheticEvent) => {
    this.props.onClick(event);
  }

  onMenuClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    this.props.onMenuClick(event);
  }

  secondsToTimeString(seconds: number): string {
    const date = new Date(0);
    if (seconds === Infinity || seconds === -Infinity) {
      seconds = 0;
    }
    date.setSeconds(seconds);
    return date.toISOString().substr(14, 5);
  }

  renderSecondLine(): JSX.Element {
    const artistName = this.props.track ? this.props.track.artist : undefined;
    const artistId = this.props.track ? this.props.track.artistId : undefined;
    const albumName = this.props.album ? this.props.album.title : undefined;
    const albumId = this.props.album ? this.props.album.id : undefined;
    if (this.props.displayAlbumName) {
      return (
        <div className="TrackCellSecondLine">
          <span>
            <Link className="TrackCellLink"
                to={`/listen/artists/${artistId}`}>
              {artistName}
            </Link>
          </span>
          <span className="TrackCellSeparator">•</span>
          <span>
            <Link className="TrackCellLink"
                to={`/listen/albums/${albumId}`}>
              {albumName}
            </Link>
          </span>
        </div>
      );
    } else {
      return (
        <div className="TrackCellSecondLine">
          <span>{artistName}</span>
        </div>
      );
    }
  }

  render() {
    let rowClassName = "TrackCellRow";
    if (this.state.hover) {
      rowClassName += " TrackCellRowHover";
    }
    if (this.props.selected) {
      rowClassName += " TrackCellRowSelected";
    }
    let actionIcon = undefined;
    if (this.props.selected) {
      if (this.state.hover) {
        actionIcon = this.props.playing ? <PauseIcon /> : <PlayIcon />;
      } else {
        actionIcon = this.state.hover ? <PauseIcon /> : <SpeakerIcon />;
      }
    } else if (this.state.hover && this.props.playable) {
      actionIcon = <PlayIcon />;
    }
    const actionClass = this.props.selected || this.props.playable ?
        "TrackCellAction" : "TrackCellAction Disabled";
    const menuButtonClass = this.state.hover ? "TrackCellMenuButton" : "TrackCellMenuButton Hidden";
    const trackTitle = this.props.track ? this.props.track.title : undefined;
    const duration = this.props.track ? this.secondsToTimeString(this.props.track.duration) : undefined;
    return (
      <div className="TrackCell">
        <li
          className={rowClassName}
          role="button"
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}>
          <div className="TrackCellColumn Action">
            <div className={actionClass} onClick={this.onClick}>
              {actionIcon}
            </div>
          </div>
          <div className="TrackCellColumn Title">
            <div className="TrackCellTitle">
              {trackTitle}
            </div>
            {this.renderSecondLine()}
          </div>
          <div className="TrackCellColumn MenuButton">
            <div className={menuButtonClass} onClick={this.onMenuClick}>
              ...
            </div>
          </div>
          <div className="TrackCellColumn Duration">{duration}</div>
        </li>
      </div>
    );
  }
}

export default TrackCell;
