export interface Dictionary<V> {
  [index:string]: V;
}

export interface Credentials {
  email: string;
  downloadUrl: string;
  downloadToken: string;
  downloadTokenExpiration: Date;
}

export interface Album {
  id: string;
  title: string;
  titleSort: string;
  artist: string;
  artistSort: string;
  artistId: string;
  year: number;
  artName: string;
  trackIds: Array<string>;
}

export function instanceOfAlbum(object: any): object is Album {
  return ['id', 'title', 'titleSort', 'artist', 'artistSort', 'artistId',
      'year', 'artName', 'trackIds']
      .reduce((is: boolean, key) => is && (key in object), true);
}

export interface Artist {
  id: string;
  name: string;
  nameSort: string;
  albumIds: Array<string>;
  artName: string;
  area: string;
}

export function instanceOfArtist(object: any): object is Artist {
  return ['id', 'name', 'nameSort', 'albumIds', 'artName', 'area']
    .reduce((is: boolean, key) => is && (key in object), true);
}

export interface Track {
  id: string;
  mediaName: string;
  title: string;
  titleSort: string;
  track: number;
  artist: string;
  artistSort: string;
  artistId: string;
  duration: number;
  albumId: string;
  album: string;
  albumSort: string;
}

export function instanceOfTrack(object: any): object is Track {
  return ['id', 'mediaName', 'title', 'titleSort', 'track', 'artist',
      'artistSort', 'artistId', 'duration', 'albumId', 'album', 'albumSort']
      .reduce((is: boolean, key) => is && (key in object), true);
}

export interface Library {
  version: string;
  tracks: Dictionary<Track>;
  albums: Dictionary<Album>;
  artists: Dictionary<Artist>;
}
