import React, { SyntheticEvent } from 'react';
import './LoginPage.css';

interface Props {
  error?: any;
  onSubmit?(email?: string, password?: string): void;
  [propName: string]: any;
}

interface State {
  email: string;
  password: string;
  [propName: string]: any;
}

class LoginPage extends React.Component<Props, State> {

  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      email: '',
      password: '',
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onInputChange(event: SyntheticEvent) {
    const target = event.target as HTMLInputElement;
    this.setState({
      [target.name]: target.value,
    });
  }

  onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    if (!this.props.onSubmit) {
      return;
    }
    let email = this.state.email.trim() || '';
    let password = this.state.password || '';
    this.props.onSubmit(email, password);
  }

  render() {
    let errorElement = undefined;
    if (this.props.error) {
      const errorString = `${this.props.error}`;
      errorElement = <div className="ErrorMessage">{errorString}</div>
    }
    return (
      <div className="LoginPage">
        <div className="LoginCenter">
          <h1>Login to Jukeboxx</h1>
          <form>
            <label htmlFor="input-email">
              Email
            </label>
            <input
              id="input-email"
              type="email"
              name="email"
              className="LoginInput"
              autoCorrect="off"
              spellCheck={false}
              autoCapitalize="off"
              placeholder="e.g. thor@avengers.net"
              value={this.state.email}
              onChange={this.onInputChange} />
            <label htmlFor="input-password">
              Password
            </label>
            <input
              id="input-password"
              type="password"
              name="password"
              className="LoginInput"
              autoCorrect="off"
              spellCheck={false}
              autoCapitalize="off"
              placeholder="e.g., ••••••••••••"
              value={this.state.password}
              onChange={this.onInputChange} />
            <input
              className="LoginButton"
              type="submit"
              value="Log In"
              onClick={this.onSubmit}>
            </input>
          </form>
          {errorElement}
        </div>
      </div>
    );
  }
}

export default LoginPage;
