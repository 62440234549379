import AlbumTrackList from './AlbumTrackList';
import AllAlbumsList from './AllAlbumsList';
import AllTracksList from './AllTracksList';
import ArtistAlbumList from './ArtistAlbumList';
import AllArtistsList from './AllArtistsList';
import LibraryProvider from '../services/libraryProvider';
import MainView from '../components/app/MainView';
import NowPlayingBar from '../components/player/NowPlayingBar';
import Player from '../components/player/Player';
import QueueList from './QueueList';
import React from 'react';
import SearchEngine from '../services/searchEngine';
import SearchPage from './SearchPage';
import { Album, Credentials, Library, Track } from '../data/structs';
import { CredentialsExpiredError } from '../data/errors';
import { Link, Router } from "@reach/router";
import './ListenPage.css';

interface Props {
  credentials?: Credentials;
  onLogOutClick?(): void;
  onError(error: Error): void;
  [propName: string]: any;
}

interface State {
  library?: Library;
  searchEngine?: SearchEngine;
  selectedTrack?: Track;
  duration: number;
  progress: number;
  playing: boolean;
  buffering: boolean;
  volume: number;
  shuffle: boolean;
  repeat: boolean;
  backStack: Array<Track>;
  nextQueue: Array<Track>;
}

class ListenPage extends React.Component<Props, State> {

  private playerRef = React.createRef<Player>();
  private libraryProvider: LibraryProvider;
  private fetchLibraryPromise?: Promise<Library>;

  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      duration: 0,
      progress: 0,
      buffering: false,
      playing: false,
      volume: 1,
      shuffle: false,
      repeat: false,
      backStack: [],
      nextQueue: [],
    };
    this.libraryProvider = new LibraryProvider();

    this.onTrackClick = this.onTrackClick.bind(this);
    this.onProgressChange = this.onProgressChange.bind(this);
    this.onDurationChange = this.onDurationChange.bind(this);
    this.onPlayStateChange = this.onPlayStateChange.bind(this);
    this.onVolumeChange = this.onVolumeChange.bind(this);
    this.onEnded = this.onEnded.bind(this);

    this.onLogOutClick = this.onLogOutClick.bind(this);
    this.onPlayPauseClick = this.onPlayPauseClick.bind(this);
    this.onBackClick = this.onBackClick.bind(this);
    this.onForwardClick = this.onForwardClick.bind(this);
    this.onProgressBarChange = this.onProgressBarChange.bind(this);
    this.onVolumeBarChange = this.onVolumeBarChange.bind(this);
    this.onShuffleToggle = this.onShuffleToggle.bind(this);
    this.onRepeatToggle = this.onRepeatToggle.bind(this);
  }

  componentDidMount() {
    if (!this.props.credentials) {
      this.onError(new CredentialsExpiredError());
      return;
    }
    this.tryFetchLibrary();
  }

  componentDidUpdate() {
    this.tryFetchLibrary();
  }

  tryFetchLibrary() {
    if (this.state.library || !this.props.credentials ||
        this.fetchLibraryPromise) {
      return;
    }
    this.fetchLibraryPromise =
        this.libraryProvider.fetchLibrary(this.props.credentials)
    this.fetchLibraryPromise.then(library => {
      this.setState({
        library,
        searchEngine: new SearchEngine(library),
      });
    }).catch(error => {
      this.onError(error);
    });
  }

  getAlbum(track?: Track): Album | undefined {
    if (!track || !this.state.library) {
      return undefined;
    }
    return this.state.library.albums[track.albumId];
  }

  onTrackClick(
    track: Track,
    nextQueue: Array<Track>,
    backStack: Array<Track>,
    shuffle: boolean
  ) {
    if (!track) {
      return;
    }

    if (shuffle) {
      const all = this.shuffle(nextQueue.concat(backStack).concat(track));
      track = all[0];
      backStack = [];
      nextQueue = all.slice(1);
    } else if (this.state.shuffle) {
      backStack = [];
      nextQueue = this.shuffle(nextQueue.concat(backStack));
    }

    if (!this.state.selectedTrack ||
        track.id !== this.state.selectedTrack.id) {
      this.setState(prevState => ({
        selectedTrack: track,
        buffering: true,
        backStack,
        nextQueue,
        shuffle: shuffle || prevState.shuffle,
      }));
    } else {
      this.onPlayPauseClick();
    }
  }

  onPlayNext = (tracks: Array<Track>) => {
    this.setState(prevState => ({
      nextQueue: tracks.concat(prevState.nextQueue),
    }));
  }

  onPlayLater = (tracks: Array<Track>) => {
    this.setState(prevState => ({
      nextQueue: prevState.nextQueue.concat(tracks),
    }));
  }

  shuffle<T>(list: Array<T>): Array<T> {
    let unstruck = list.slice();
    let shuffled = [];

    let k = 0;
    while (unstruck.length > 0) {
      k =  Math.floor(Math.random() * unstruck.length);
      shuffled.push(unstruck[k]);
      unstruck.splice(k, 1);
    }

    return shuffled;
  }

  onProgressChange(progress: number) {
    this.setState({
      progress,
    });
  }

  onDurationChange(duration: number) {
    this.setState({
      duration,
    });
  }

  onPlayStateChange(playing: boolean) {
    this.setState({
      playing,
    });
  }

  onBufferingStarted = () => {
    if (this.state.playing && this.state.selectedTrack) {
      this.setState({
        buffering: true,
      });
    }
  }

  onBufferingEnded = () => {
    this.setState({
      buffering: false,
    });
  }

  onVolumeChange(volume: number) {
    this.setState({
      volume,
    });
  }

  onEnded() {
    this.onForwardClick();
  }

  onError = (error: Error) => {
    this.props.onError(error);
  }

  // Interactions

  onLogOutClick() {
    if (this.props.onLogOutClick) {
      this.props.onLogOutClick();
    }
  }

  onPlayPauseClick() {
    if (!this.playerRef.current) {
      return;
    }
    this.playerRef.current.togglePlayPause();
  }

  onBackClick() {
    if (!this.state.backStack.length) {
      if (this.state.repeat) {
        // TODO: Corner case where need to restart the song if just one.
        let all = this.state.selectedTrack ?
            [this.state.selectedTrack].concat(this.state.nextQueue) :
            this.state.nextQueue;
        all = all.reverse();
        this.setState({
          selectedTrack: all[0],
          buffering: true,
          backStack: all.slice(1),
          nextQueue: [],
        });
        return;
      } else {
        this.setState({
          selectedTrack: undefined,
          buffering: false,
          duration: 0,
          progress: 0,
          playing: false,
          backStack: [],
          nextQueue: [],
        });
        return;
      }
    }
    const prevTrack = this.state.backStack[0];
    const nextQueue = this.state.selectedTrack ?
        [this.state.selectedTrack].concat(this.state.nextQueue) :
        this.state.nextQueue;
    this.setState({
      selectedTrack: prevTrack,
      buffering: true,
      backStack: this.state.backStack.slice(1),
      nextQueue,
    });
  }

  onForwardClick() {
    if (!this.state.nextQueue.length) {
      if (this.state.repeat) {
        // TODO: Corner case where need to restart the song if just one.
        let all = this.state.selectedTrack ?
            [this.state.selectedTrack].concat(this.state.backStack) :
            this.state.backStack;
        all = all.reverse();
        this.setState({
          selectedTrack: all[0],
          buffering: true,
          backStack: [],
          nextQueue: all.slice(1),
        });
        return;
      } else {
        this.setState({
          selectedTrack: undefined,
          buffering: false,
          duration: 0,
          progress: 0,
          playing: false,
          backStack: [],
          nextQueue: [],
        });
        return;
      }
    }
    const nextTrack = this.state.nextQueue[0];
    const backStack = this.state.selectedTrack ?
        [this.state.selectedTrack].concat(this.state.backStack) :
        this.state.backStack;
    this.setState({
      selectedTrack: nextTrack,
      buffering: true,
      backStack: backStack,
      nextQueue: this.state.nextQueue.slice(1),
    });
  }

  onProgressBarChange(progress: number) {
    if (!this.playerRef.current) {
      return;
    }
    this.setState({
      progress,
    });
    this.playerRef.current.seekToTime(progress);
  }

  onVolumeBarChange(volume: number) {
    if (!this.playerRef.current) {
      return;
    }
    this.setState({
      volume,
    });
    this.playerRef.current.setVolume(volume);
  }

  onShuffleToggle() {
    this.setState(prevState => ({
      shuffle: !prevState.shuffle,
    }));
  }

  onRepeatToggle() {
    this.setState(prevState => ({
      repeat: !prevState.repeat,
    }));
  }

  renderMainView(): JSX.Element {
    if (!this.state.library || !this.state.searchEngine ||
        !this.props.credentials) {
      return <div className="MainView"></div>;
    }
    return (
      <MainView className="MainView" path={document.location.pathname}>
        <Router primary={false}>
        <SearchPage
              path="/search"
              credentials={this.props.credentials}
              library={this.state.library}
              searchEngine={this.state.searchEngine}
              onTrackClick={this.onTrackClick}
              onPlayNext={this.onPlayNext}
              onPlayLater={this.onPlayLater}
              onError={this.onError} />
          <QueueList
              path="/queue"
              library={this.state.library}
              playing={this.state.playing}
              selectedTrack={this.state.selectedTrack}
              nextQueue={this.state.nextQueue}
              onPlayNext={this.onPlayNext}
              onPlayLater={this.onPlayLater}
              onTrackClick={this.onTrackClick} />
          <AllTracksList
              path="/tracks"
              library={this.state.library}
              selectedTrack={this.state.selectedTrack}
              playing={this.state.playing}
              onTrackClick={this.onTrackClick}
              onPlayNext={this.onPlayNext}
              onPlayLater={this.onPlayLater} />
          <AllAlbumsList
              path="/albums"
              credentials={this.props.credentials}
              library={this.state.library}
              onError={this.onError} />
          <AlbumTrackList
              path="/albums/:albumId"
              credentials={this.props.credentials}
              library={this.state.library}
              selectedTrack={this.state.selectedTrack}
              playing={this.state.playing}
              onTrackClick={this.onTrackClick}
              onPlayNext={this.onPlayNext}
              onPlayLater={this.onPlayLater}
              onError={this.onError} />
          <AllArtistsList
              path="/artists"
              credentials={this.props.credentials}
              library={this.state.library}
              onError={this.onError} />
          <ArtistAlbumList path="/artists/:artistId"
              credentials={this.props.credentials}
              library={this.state.library}
              playing={this.state.playing}
              onTrackClick={this.onTrackClick}
              onError={this.onError} />
        </Router>
      </MainView>
    );
  }

  render() {
    const email = this.props.credentials ?
        this.props.credentials.email : undefined;
    const searchSelected =
        document.location.pathname.startsWith('/listen/search');
    const queueSelected =
        document.location.pathname.startsWith('/listen/queue');
    const tracksSelected =
        document.location.pathname.startsWith('/listen/tracks');
    const albumsSelected =
        document.location.pathname.startsWith('/listen/albums');
    const artistsSelected =
        document.location.pathname.startsWith('/listen/artists');
    return (
      <div className="ListenPage">
        <div className="NavBar">
          <div className="NavBarTitle">
            <h1 className="NavBarTitleName">Jukeboxx</h1>
          </div>
          <div className="NavBarMenu">
          <h2 className={`NavBarMenuItem ${searchSelected ? 'Selected' : ''}`}>
              <Link to="/listen/search">
                Search
              </Link>
            </h2>
            <h2 className={`NavBarMenuItem ${queueSelected ? 'Selected' : ''}`}>
              <Link to="/listen/queue">
                Up Next
              </Link>
            </h2>
            <div className="NavBarMenuItemSpacer"></div>
            <h2 className={`NavBarMenuItem ${tracksSelected ? 'Selected' : ''}`}>
              <Link to="/listen/tracks">
                Tracks
              </Link>
            </h2>
            <h2 className={`NavBarMenuItem ${albumsSelected ? 'Selected' : ''}`}>
              <Link to="/listen/albums">
                Albums
              </Link>
            </h2>
            <h2 className={`NavBarMenuItem ${artistsSelected ? 'Selected' : ''}`}>
              <Link to="/listen/artists">
                Artists
              </Link>
            </h2>
            <div className="NavBarMenuItemSpacer"></div>
            <h2 className="NavBarMenuItem">
              <a href="https://nicolaschavez.com/projects/jukeboxx-web-v3/"
                 target="_blank"
                 rel="noopener noreferrer">
                About
              </a>
            </h2>
          </div>
          <div className="NavBarFooter">
            <div className="AccountInfo">
              <div className="UserId">{email}</div>
            </div>
            <button className="LogOutButton" onClick={this.onLogOutClick}>
              Log Out
            </button>
          </div>
        </div>
        {this.renderMainView()}
        <NowPlayingBar
            credentials={this.props.credentials}
            track={this.state.selectedTrack}
            album={this.getAlbum(this.state.selectedTrack)}
            progress={this.state.progress}
            duration={this.state.duration}
            playing={this.state.playing}
            buffering={this.state.buffering}
            volume={this.state.volume}
            repeat={this.state.repeat}
            shuffle={this.state.shuffle}
            onProgressChange={this.onProgressBarChange}
            onPlayPauseClick={this.onPlayPauseClick}
            onBackClick={this.onBackClick}
            onForwardClick={this.onForwardClick}
            onVolumeChange={this.onVolumeBarChange}
            onShuffleToggle={this.onShuffleToggle}
            onRepeatToggle={this.onRepeatToggle}
            onError={this.onError} />
        <Player
            ref={this.playerRef}
            credentials={this.props.credentials}
            track={this.state.selectedTrack}
            onProgressChange={this.onProgressChange}
            onDurationChange={this.onDurationChange}
            onStateChange={this.onPlayStateChange}
            onBufferingStarted={this.onBufferingStarted}
            onBufferingEnded={this.onBufferingEnded}
            onVolumeChange={this.onVolumeChange}
            onEnded={this.onEnded}
            onError={this.onError}/>
      </div>
    );
  }
}

export default ListenPage;
