const BUILD_VERSION = process.env.BUILD_VERSION;

const DOWNLOAD_BASE_PATH = process.env.DOWNLOAD_BASE_PATH;

const SERVER_URL = process.env.SERVER_URL || window.location.origin;

export {
  BUILD_VERSION,
  DOWNLOAD_BASE_PATH,
  SERVER_URL,
}
