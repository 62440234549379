import React from 'react';
import './ContextMenu.css';

interface Props {
  display: boolean;
  clientClick: {x: number, y: number};
  offset: {x: number, y: number};
  menuOptions: Array<string>;
  onClick(index: number): void;
}

class ContextMenu extends React.Component<Props> {

  private containerRef = React.createRef<HTMLDivElement>();

  onClick = (index: number) => {
    this.props.onClick(index);
  }

  render() {
    const menuClass = this.props.display ? 'ContextMenu' : 'ContextMenu Closed';
    const container = this.containerRef.current;
    let menuStyle = {};
    if (container != null) {
      const containerRect = container.getBoundingClientRect();
      menuStyle = {
        left: `${this.props.clientClick.x - containerRect.left + this.props.offset.x}px`,
        top: `${this.props.clientClick.y - containerRect.top + this.props.offset.y}px`,
      };
    }
    const menuOptions = this.props.menuOptions.map((menuOption, index) =>
        <li className="ContextMenuOption"
            key={index}
            onClick={() => this.onClick(index)}>
          {menuOption}
        </li>
    );
    return (
      <div className="ContextMenuContainer" ref={this.containerRef}>
        <div className={menuClass} style={menuStyle}>
          <ul className="ContextMenuOptions">
            {menuOptions}
          </ul>
        </div>
      </div>
    );
  }
}

export default ContextMenu;
