import Hls from 'hls.js';
import React from 'react';
import { Credentials, Track } from '../../data/structs';
import { CredentialsExpiredError, HlsError, PlaybackError} from '../../data/errors';
import { DOWNLOAD_BASE_PATH } from '../../data/constants';

enum LoadState {
  NO_MEDIA,
  MEDIA_ATTACHING,
  MEDIA_ATTACHED,
  MANIFEST_LOADING,
  MANIFEST_LOADED,
  PLAYING,
};

interface Props {
  credentials?: Credentials;
  track?: Track;

  onProgressChange?(progress: number): void;
  onDurationChange?(duration: number): void;
  onStateChange?(playing: boolean): void;
  onBufferingStarted(): void;
  onBufferingEnded(): void;
  onVolumeChange?(volume: number): void;
  onEnded?(): void;
  onError(error: Error): void;
}

interface State {
  mediaUrl?: string;
  loadState: LoadState;
}

class Player extends React.Component<Props, State> {

  audioElementRef = React.createRef<HTMLAudioElement>();
  hls?: Hls;

  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      mediaUrl: undefined,
      loadState: LoadState.NO_MEDIA,
    };
  }

  componentDidMount() {
    if (!Hls.isSupported()) {
      console.error('Audio playback not supported.');
      return;
    }
    const audioElement = this.audioElementRef.current;
    if (!audioElement) {
      return;
    }
    this.hls = this.buildHls(audioElement);
    audioElement.addEventListener('timeupdate',
        (e) => this.onAudioProgressChange(e));
    audioElement.addEventListener('durationchange',
        (e) => this.onAudioDurationChange(e));
    audioElement.addEventListener('play', (e) => this.onAudioStateChange(e));
    audioElement.addEventListener('pause', (e) => this.onAudioStateChange(e));
    audioElement.addEventListener('playing', (e) => this.onAudioPlaying(e));
    audioElement.addEventListener('waiting', (e) => this.onAudioWaiting(e));
    audioElement.addEventListener('volumechange',
        (e) => this.onAudioVolumeChange(e));
    audioElement.addEventListener('ended', (e) => this.onAudioEnded(e));
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.track === prevProps.track || !this.hls ||
        !this.props.credentials || !this.audioElementRef.current ||
        this.state.loadState === LoadState.MEDIA_ATTACHING) {
      return;
    }
    if ([LoadState.MANIFEST_LOADING,
        LoadState.MANIFEST_LOADED,
        LoadState.PLAYING].includes(this.state.loadState)) {
      this.hls.destroy();
      this.hls = this.buildHls(this.audioElementRef.current);
    }
    if (!this.props.track) {
      return;
    }
    if (this.props.credentials.downloadTokenExpiration < new Date()) {
      this.props.onError(new CredentialsExpiredError());
      return;
    }
    const trackUrl =
        this.buildManifestUrl(this.props.credentials, this.props.track);
    this.hls.loadSource(trackUrl);
  }

  togglePlayPause() {
    if (!this.audioElementRef.current) {
      return;
    }
    if (this.audioElementRef.current.paused) {
      this.audioElementRef.current.play()
      .catch(reason => this.onAudioError(reason));
    } else {
      this.audioElementRef.current.pause();
    }
  }
  
  seekToTime(time: number) {
    if (!this.audioElementRef.current) {
      return;
    }
    this.audioElementRef.current.currentTime = time;
  }

  setVolume(volume: number) {
    if (!this.audioElementRef.current) {
      return;
    }
    this.audioElementRef.current.volume = volume;
  }

  onAudioProgressChange(event: Event) {
    if (!this.props.onProgressChange) {
      return;
    }
    const audioElement = event.target as HTMLAudioElement;
    this.props.onProgressChange(audioElement.currentTime);
  }

  onAudioDurationChange(event: Event) {
    if (!this.props.onDurationChange) {
      return;
    }
    const audioElement = event.target as HTMLAudioElement;
    this.props.onDurationChange(audioElement.duration);
  }

  onAudioStateChange(event: Event) {
    if (!this.props.onStateChange) {
      return;
    }
    const audioElement = event.target as HTMLAudioElement;
    this.props.onStateChange(!audioElement.paused);
  }

  onAudioPlaying(event: Event) {
    this.props.onBufferingEnded();
  }

  onAudioWaiting(event: Event) {
    this.props.onBufferingStarted();
  }

  onAudioVolumeChange(event: Event) {
    if (!this.props.onVolumeChange) {
      return;
    }
    const audioElement = event.target as HTMLAudioElement;
    this.props.onVolumeChange(audioElement.volume);
  }

  onAudioEnded(event: Event) {
    if (!this.props.onEnded) {
      return;
    }
    this.props.onEnded();
  }

  onAudioError(reason: any) {
    this.props.onError(new PlaybackError(reason));
  }

  onHlsMediaAttached() {
    this.setState({
      loadState: LoadState.MEDIA_ATTACHED,
    });
    if (!this.props.track || !this.props.credentials || !this.hls) {
      return;
    }
    if (this.props.credentials.downloadTokenExpiration < new Date()) {
      this.props.onError(new CredentialsExpiredError());
      return;
    }
    const url =
        this.buildManifestUrl(this.props.credentials, this.props.track);
    this.hls.loadSource(url);
    this.setState({
      loadState: LoadState.MANIFEST_LOADING,
    });
  }

  onHlsManifestParsed() {
    this.setState({
      loadState: LoadState.MANIFEST_LOADED,
    });
    const audioElement = this.audioElementRef.current;
    if (!audioElement) { return; }
    audioElement.play()
      .then(() => {
        this.setState({
          loadState: LoadState.PLAYING,
        });
      })
      .catch(reason => this.onAudioError(reason));
  }

  onHlsError(event: string, data: Hls.errorData) {
    this.props.onError(new HlsError(data));
  }

  buildHls(audioElement: HTMLAudioElement): Hls {
    const config = Object.assign({}, Hls.DefaultConfig);
    // https://github.com/video-dev/hls.js/issues/2064#issuecomment-469888615
    config.enableWorker = false;
    config.xhrSetup = (xhr, url) => {
      if (this.props.credentials) {
        url = `${url}?Authorization=${this.props.credentials.downloadToken}`;
      }
      xhr.open('GET', url); 
    };
    config.fetchSetup = (context, initParams) => {
      context.url = context.url + `?Authorization=${'lol'}`;
      if (this.props.credentials) {
        context.url = `${context.url}?Authorization=` +
            `${this.props.credentials.downloadToken}`;
      }
      return new Request(context.url, initParams);
    };
    const hls = new Hls(config);
    hls.on(Hls.Events.MEDIA_ATTACHED, () => this.onHlsMediaAttached());
    hls.on(Hls.Events.MANIFEST_PARSED, () => this.onHlsManifestParsed());
    hls.on(Hls.Events.ERROR, (event, data) => this.onHlsError(event, data));
    hls.attachMedia(audioElement as HTMLVideoElement);
    return hls;
  }

  buildManifestUrl(credentials: Credentials, track: Track): string {
    return `${credentials.downloadUrl}${DOWNLOAD_BASE_PATH}/` +
        `tracks/${track.mediaName}/master.m3u8`;
  }

  render() {
    return (
      <div className="Player">
        <audio ref={this.audioElementRef}></audio>
      </div>
    );
  }
}

export default Player;
