import ImageProvider from '../../services/imageProvider';
import React from 'react';
import { Artist, Credentials } from '../../data/structs';
import { CSSTransition } from 'react-transition-group';
import { Link } from '@reach/router';
import { DOWNLOAD_BASE_PATH } from '../../data/constants';
import './ArtistCell.css';

interface Props {
  artist: Artist;
  index: number;
  credentials: Credentials;
  intersectionObserver?: IntersectionObserver;
  intersected: boolean;

  onError(error: Error): void;
}

interface State {
  imageSrc?: string;
  hover: boolean;
}

class ArtistCell extends React.Component<Props, State> {
  
  private imageProvider_ = ImageProvider.getInstance();
  private imagePromise_?: Promise<string>;
  private imageElement_ = React.createRef<HTMLImageElement>();
  private didStartObservation_: boolean = false;

  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      hover: false,
    };

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  componentDidMount() {
    this.tryImageFetch_();
    this.tryObservation_();
  }

  componentDidUpdate() {
    this.tryImageFetch_();
    this.tryObservation_();
  }

  private tryImageFetch_() {
    if (this.imagePromise_ || this.state.imageSrc != null) {
      return;
    }
    const url = `${this.props.credentials.downloadUrl}${DOWNLOAD_BASE_PATH}` +
        `/artist_art/${this.props.artist.artName}`;
    this.imagePromise_ =
        this.imageProvider_.fetchImage(url, this.props.credentials);
    this.imagePromise_.then(imageSrc => {
      this.setState({
        imageSrc,
      });
    }).catch(error => {
      if (error instanceof Error) {
        this.props.onError(error);
      }
    });
  }

  private tryObservation_() {
    if (this.didStartObservation_ ||
      this.props.intersectionObserver == null ||
      this.imageElement_.current == null) {
      return;
    }
    this.props.intersectionObserver.observe(this.imageElement_.current);
    this.didStartObservation_ = true;
  }

  onMouseEnter() {
    this.setState({
      hover: true,
    });
  }

  onMouseLeave() {
    this.setState({
      hover: false,
    });
  }

  render() {
    const clickClassName = this.state.hover ? 'ArtistArtClick Hover' : 'ArtistArtClick';
    return (
      <div className="ArtistCell">
        <div className="ArtistArt">
          <CSSTransition classNames="ArtistArtImageFade"
              in={this.state.imageSrc != null} timeout={450}>
            <img className="ArtistArtImage" ref={this.imageElement_}
              data-id={this.props.artist.id} src={this.state.imageSrc} alt="">
            </img>
          </CSSTransition>
          <Link className={clickClassName}
              to={`/listen/artists/${this.props.artist.id}`}
              onMouseEnter={this.onMouseEnter}
              onMouseLeave={this.onMouseLeave} />
        </div>
        <div className="ArtistCaption">
          <div className="ArtistTitle">{this.props.artist.name}</div>
        </div>
      </div>
    );
  }
}

export default ArtistCell;
